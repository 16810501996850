import { Order } from '@wix/ambassador-ecom-v1-order/types';
import {
  BookedEntity as BookedEntityV2,
  ExtendedBooking,
  BookingStatus,
  ServiceChoices,
  ConferencingDetails,
} from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import { BookingDTO, Bookings, VideoConference } from '../../../types';
import { getServiceIdFromExtendedBooking } from '../../bookInfo';

export const mapConferencingDetailsToVideoConference = (
  conference: ConferencingDetails,
): VideoConference => ({
  link: conference.guestUrl || '',
  password: conference.password as string,
  description: conference.description as string,
});

export const bookingToBookingDTO = ({
  bookingEntry,
  services,
  businessAddress,
}: {
  bookingEntry: ExtendedBooking;
  services: Service[];
  businessAddress?: string | null;
}): BookingDTO => {
  const { booking = {} } = bookingEntry;
  const booked: BookedEntityV2 = booking.bookedEntity!;
  const slot = booked.slot;
  const schedule = booked.schedule;
  const start = booking.startDate;
  const end = booking.endDate;
  let sessionId, staffInfo, locationInfo, locationId, groupId, dynamicPriceInfo;

  if (slot) {
    staffInfo = slot?.resource
      ? {
          id: slot.resource.id!,
          fullName: slot.resource.name!,
        }
      : null;
    locationInfo = slot?.location?.formattedAddress || businessAddress;
    locationId = slot?.location?.id;
    sessionId = slot.sessionId;
  } else {
    locationInfo = schedule?.location?.formattedAddress || businessAddress;
    locationId = schedule?.location?.id;
  }

  if (booking?.participantsChoices) {
    const serviceChoices: ServiceChoices[] | undefined =
      booking.participantsChoices.serviceChoices;
    if (
      serviceChoices?.length === 1 &&
      serviceChoices[0]?.numberOfParticipants === 1
    ) {
      dynamicPriceInfo = serviceChoices[0]?.choices?.[0]?.custom;
    } else {
      dynamicPriceInfo = (
        serviceChoices?.map((serviceChoice: ServiceChoices) => {
          return `${serviceChoice.choices?.[0]?.custom} × ${serviceChoice.numberOfParticipants}`;
        }) || []
      ).join(',');
    }
  }

  if (booking?.multiServiceBookingInfo) {
    groupId = booking.multiServiceBookingInfo.id;
  }

  const relatedService = services?.find(
    (service) => service.id === getServiceIdFromExtendedBooking(bookingEntry),
  )!;

  if (relatedService) {
    relatedService.name = booking?.bookedEntity?.title || '';
  }

  const withCancellationFeePolicy =
    !!bookingEntry.bookingPolicySettings?.cancellationFeeEnabled;

  const videoConference = bookingEntry.conferencingDetails
    ? mapConferencingDetailsToVideoConference(bookingEntry.conferencingDetails)
    : undefined;

  return {
    sessionId,
    locationInfo,
    locationId,
    staffInfo,
    dynamicPriceInfo,
    groupId,
    status: booking!.status || BookingStatus.CONFIRMED,
    contactId: booking?.contactDetails?.contactId!,
    bookingId: booking.id!,
    service: relatedService,
    numOfParticipants: booking.numberOfParticipants,
    start: new Date(start!).getTime(),
    end: new Date(end!).getTime(),
    allowedActions: bookingEntry?.allowedActions!,
    revision: booking.revision,
    withCancellationFeePolicy,
    ...(videoConference ? { videoConference } : {}),
  };
};

export const addOrder = ({
  bookings,
  orders,
}: {
  bookings: Bookings;
  orders?: Order[];
}): Bookings => {
  const mappedBookings = {
    ...bookings,
    groups: bookings.groups.map((group) => {
      const { order } = getOrder(group.bookings[0].bookingId, orders || []);

      return {
        ...group,
        order,
      };
    }),
  };

  return mappedBookings;
};

export const getOrder = (bookingId: string, orders: Order[]) => {
  const relevantOrder = orders.find(
    (order) =>
      !!order.lineItems?.find(
        (lineItem) => lineItem.catalogReference?.catalogItemId === bookingId,
      ),
  );

  return {
    order: relevantOrder,
  };
};
